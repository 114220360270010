import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class InOutReportService {

  common_performance_list: any;
  compare_player_assessment_score: any;
  deregister_students_details: any;
  player_performance_statistics: any;
  performance_assessment_list: any;
  player_list: any;
  attendance_report_details: any;
  session_attendance_report_details: any;
  center_data: any;
  batch_data: any;
  assessmentPerformanceScoreReportCard: any; 

  httpOptions;  
  date_format: string;
  temp_date: string;

  constructor(private http_client: HttpClient) 
  {   
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',     
        // 'auth_key': '' + localStorage.getItem("auth_key"),
        // 'device_id': '' + localStorage.getItem("device_id"),
        // 'session_id': '' + localStorage.getItem("session_id"),
        'Authorization':''+ localStorage.getItem("access_token")
        })
    };
  }

  getPlayerList(flag, organization_id) {
    const  params = new  HttpParams().set('flag', flag).set('organization_id', organization_id);
    const local_playerlist_url = AppComponent.API_URL + 'player/GetPlayerListCommon';
    //const local_playerlist_url = AppComponent.API_URL + 'player/GetPlayerList';
    return this.http_client.post(local_playerlist_url, params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.player_list =data['center_player_data'];
             if(this.player_list.length != 0)
            {          
                return this.player_list;
            }
            else
            {
                return '';
            }
          }),
        )
  }

  getCommonPerformanceSetList(player_id) {
   
    const  params = new  HttpParams().set('player_id', player_id);
    const common_performanceSet_list_url = AppComponent.API_URL + 'player/GetPlayerPerformanceSet';
    return this.http_client.post(common_performanceSet_list_url, params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.common_performance_list =data['player_performance_set_details'];
             if(this.common_performance_list.length != 0)
            {          
                return this.common_performance_list;
            }
            else
            {
                return '';
            }
          }),
        )
  }
  GetAssessmentListWithCommonSet(performance_set_id, player_id) {
    
    const  params = new  HttpParams().set('performance_set_id', performance_set_id).set('player_id', player_id);
    const assessmentlist_with_commonset_url = AppComponent.API_URL + 'webadmin/GetAssessmentListWithCommonSet';
    return this.http_client.post(assessmentlist_with_commonset_url, params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            if(data['status']==200)
            {          
                return data;
            }
            else
            {
                return '';
            }
          }),
        )
  }
  GetComparePlayerAssessmentScore(performance_set_assessment_id) {
    let params = new HttpParams();
    for (var i = 0; i < performance_set_assessment_id.length; i++) {
      params = params.append('performance_set_assessment_id[' + i + ']', performance_set_assessment_id[i]);
    }
    const compare_player_assessment_score_url = AppComponent.API_URL + 'webadmin/ComparePlayerAssessmentScore';
    return this.http_client.post(compare_player_assessment_score_url, params, this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.compare_player_assessment_score =data['compare_player_assessment_score'];
             if(this.compare_player_assessment_score.length != 0)
            {          
                return this.compare_player_assessment_score;
            }
            else
            {
                return '';
            }
          }),
        )
  }

  GetPlayerListAssessment(performance_set_assessment_id) {
    let params = new HttpParams();

    for (var i = 0; i < performance_set_assessment_id.length; i++) {
      params = params.append('performance_set_assessment_id[' + i + ']', performance_set_assessment_id[i]);
    }

    const getPlayerPerformanceStatistics_url = AppComponent.API_URL + 'player/GetPlayerListAssessment';
    return this.http_client.post(getPlayerPerformanceStatistics_url, params, this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.performance_assessment_list =data['performance_assessment_list'];
             if(this.performance_assessment_list.length != 0)
            {          
                return this.performance_assessment_list;
            }
            else
            {
                return '';
            }
          }),
        )

  }

  DeRegisterBulkStudents(login_customer_id, organization_id, login_role_id, player_data) {
    let params = new HttpParams();
    params = params.append('login_customer_id', login_customer_id);
    params = params.append('organization_id', organization_id);
    params = params.append('login_role_id', login_role_id);
    params = params.append('player_data', player_data);

    const deregister_bulk_player_url = AppComponent.API_URL + 'webadmin/DeRegisterBulkStudents';
    return this.http_client.post(deregister_bulk_player_url, params, this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.deregister_students_details =data['deregister_bulk_students_details'];
             if(this.deregister_students_details.length != 0)
            {          
                return this.deregister_students_details;
            }
            else
            {
                return '';
            }
          }),
        )
  }

  getPlayerPerformanceStatistics(organization_id, player_id, performance_set_assessment_id) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('player_id', player_id);
    for (var i = 0; i < performance_set_assessment_id.length; i++) {
      params = params.append('performance_set_assessment_id[' + i + ']', performance_set_assessment_id[i]);
    }

    const getPlayerPerformanceStatistics_url = AppComponent.API_URL + 'dashboard/GetPlayerPerformanceStatistics';
    return this.http_client.post(getPlayerPerformanceStatistics_url, params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.player_performance_statistics =data['player_performance_statistics'];
             if(this.player_performance_statistics.length != 0)
            {          
                return this.player_performance_statistics;
            }
            else
            {
                return '';
            }
          }),
        )
  }

  GetAttendanceReport(organization_id,  academy_center_id, from_date,to_date, user_type,status) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    if(academy_center_id!=undefined){
    academy_center_id.forEach((element,i) => {
      params = params.append('academy_center_id' + '[' + i + ']', element);
    }); }
    if (user_type == 'staff') {
      params = params.append('is_staff', 'Y');
    }else{
      params = params.append('is_staff', 'N');
    }
    if (from_date!=undefined && from_date!='') {
      params = params.append('from',this.dateFormater(from_date));
    }
    if ( to_date!=undefined && to_date!='') {
      params = params.append('to',this.dateFormater(to_date));
    }
    if(status!=undefined && status!=null){
      params=params.append('filters[status]',status);
    }
    const deregister_bulk_player_url = AppComponent.API_URL + 'dashboard/GetAttendanceReport';
    return this.http_client.post(deregister_bulk_player_url, params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.attendance_report_details =data['attendance_report_details'];
             if(this.attendance_report_details.length != 0)
            {          
                return this.attendance_report_details;
            }
            else
            {
                return '';
            }
          }),
        )
  }

  getAttendanceReportParentLevel(organization_id,academy_center_id, from_date,to_date,
     is_staff,child_academy,status) {
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    if(academy_center_id!=undefined){
      academy_center_id.forEach((element,i) => {
        params = params.append('academy_center_id' + '[' + i + ']', element);
      }); }
      
    if (is_staff == 'staff') {
      params = params.append('is_staff', 'Y');
    }else{
      params = params.append('is_staff', 'N');
    }
    if (from_date!=undefined && from_date!='') {
      params = params.append('from',this.dateFormater(from_date));
    }
    if ( to_date!=undefined && to_date!='') {
      params = params.append('to',this.dateFormater(to_date));
    }

    child_academy.forEach((element,i) => {
      params =params.append('filters[organization_id]['+i+']', element);
    });
    if(status!=undefined && status!=null){
      params=params.append('filters[status]',status);
    }
    const deregister_bulk_player_url = AppComponent.API_URL + 'dashboard/GetAttendanceReportParentLevel';
    return this.http_client.post(deregister_bulk_player_url, params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.attendance_report_details =data['attendance_report_details'];
             if(this.attendance_report_details.length != 0)
            {          
                return this.attendance_report_details;
            }
            else
            {
                return '';
            }
          }),
        )
  }

  GetSessionsAttendedReport(organization_id, filter_value, filter_type, age_flag,date_format) {
    
    let params = new HttpParams();
    params = params.append('organization_id', organization_id);
    params = params.append('date_format', date_format);
    if (filter_value.length != 0) 
    {
      for (var i = 0; i < filter_type.length; i++) 
      {
        if (filter_type[i] == "Current") 
        {
          params = params.append('filters[current]', filter_value[i]['Current']);
        }
        else if (filter_type[i] == "Date")
         {
          if (filter_value[i]['Date'][0] != null && filter_value[i]['Date'][1] != null) {
            params = params.append('filters[from_date]', filter_value[i]['Date'][0]);
            params = params.append('filters[to_date]', filter_value[i]['Date'][1]);
          }
          else if (age_flag == "Y") {
            params = params.append('filters[from_date]', filter_value[i]['Date'][0]);
          }
          else if (age_flag == "N") {
            params = params.append('filters[to_date]', filter_value[i]['Date'][0]);
          }
        }
        else if (filter_type[i] == "Center") 
        {
          for (var j = 0; j < filter_value[i]['Center'].length; j++) 
          {
            params = params.append('filters[center]' + '[' + j + ']', filter_value[i]['Center'][j]);
          }
        }
        else if (filter_type[i] == "Batch") 
        {
          for (var j = 0; j < filter_value[i]['Batch'].length; j++) 
          {
            params = params.append('filters[batch]' + '[' + j + ']', filter_value[i]['Batch'][j]);
          }
        } 
      }
    }

    const student_session_report_url = AppComponent.API_URL + 'dashboard/GetSessionsAttendedReport';
    return this.http_client.post(student_session_report_url, params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.session_attendance_report_details =data['session_attended_report_details'];
             if(this.session_attendance_report_details.length != 0)
            {          
                return this.session_attendance_report_details;
            }
            else
            {
                return '';
            }
          }),
        )
  }

  getCenterDetails(customer_id, organization_id, is_admin) {
    let params = new HttpParams();  
    params = params.append('customer_id', customer_id);
    params = params.append('organization_id', organization_id);
    params = params.append('is_admin', is_admin);
    
    const local_center_url = AppComponent.API_URL + 'center/GetCenterListWithGroups ';
    return this.http_client.post(local_center_url, params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.center_data =data['Academy Center Ids'];
             if(this.center_data.length != 0)
            {          
                return this.center_data;
            }
            else
            {
                return '';
            }
          }),
        )

  }
  GetBatchsByAcademy(organization_id) {
    let params = new HttpParams();  
    params = params.append('organization_id', organization_id);
    
    const local_center_url = AppComponent.API_URL + 'batch/GetBatchsByAcademy';
    return this.http_client.post(local_center_url, params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.batch_data =data['batch'];
             if(this.batch_data.length != 0)
            {          
                return this.batch_data;
            }
            else
            {
                return '';
            }
          }),
        )
  }

  getAssesmentPerformanceSetScoreReportCard(academy_id,performance_set_assessment_id,filter_value,filter_type,player_id)
  {
    let params = new HttpParams();
    params = params.append('academy_id', academy_id);
    params = params.append('performance_set_assessment_id', performance_set_assessment_id);
    if(player_id!=undefined || player_id!=null)
    {
      params = params.append('filter[player_id]', player_id);
    }
    
    if (filter_value.length != 0) 
    {
      for (var i = 0; i < filter_type.length; i++) 
      {       
        if (filter_type[i] == "Center") 
        {
          for (var j = 0; j < filter_value[i]['Center'].length; j++) 
          {
            params = params.append('filter[academy_center_id]' + '[' + j + ']', filter_value[i]['Center'][j]);
          }
        }
        else if (filter_type[i] == "Batch") 
        {
          for (var j = 0; j < filter_value[i]['Batch'].length; j++) 
          {
            params = params.append('filter[academy_group_id]' + '[' + j + ']', filter_value[i]['Batch'][j]);
          }
        } 
      }
    }
  
    const getPlayerPerformanceStatistics_url = AppComponent.API_URL + 'performance/GetAssesmentPerformanceSetScore';
    return this.http_client.post(getPlayerPerformanceStatistics_url, params,this.httpOptions)
    .pipe(
          map((data:any) =>{
            this.assessmentPerformanceScoreReportCard =data['assesment'];
             if(this.assessmentPerformanceScoreReportCard.length != 0)
            {          
                return this.assessmentPerformanceScoreReportCard;
            }
            else
            {
                return '';
            }
          }),
        )
  }

  dateFormater(date)
  {  
    this.date_format = sessionStorage.getItem('date_format'); 
    var day1=new Date(date);
    var month:any;
    var day;  
    day=day1.getDate();
    month=(day1.getMonth() + 1);
    if(day<10)
    {
      day="0"+day;
    }
    if(month<10)
    {
      month="0"+month;
    }
    if (this.date_format=="Y-m-d") 
    {      
      return this.temp_date = day1.getFullYear()+"-"+month+"-"+day;
    }
    if (this.date_format=="d-m-Y") 
    {      
      return this.temp_date = day+ "-"+month+"-"+day1.getFullYear();
    }
    if (this.date_format=="Y/m/d") 
    {      
      return this.temp_date = day1.getFullYear()+"/"+ month+"/"+day;
    }
  
    if (this.date_format=="d/m/Y") 
    {      
      return this.temp_date = day+"/"+month+"/"+day1.getFullYear();
    }
  
    if (this.date_format=="m/d/Y") 
    {      
      return this.temp_date =day+"/"+month+"/"+day1.getFullYear();
    }   
       
      
  }

  getAttendanceConfiguration(organization_id){

    const  params = new  HttpParams().set('organization_id', organization_id);
    
    const local_url = AppComponent.API_URL + 'entitlement/GetAttendanceConfiguration';  
    return this.http_client.post(local_url, params,this.httpOptions)
    .pipe(
            map((data:any) =>{
             return data
            }),
        )
  }
}